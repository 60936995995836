import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceModel } from '../../common/models/common.model';
import { BackEndCommunicationService } from '../../common/services/beComm.service';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { UtilsService } from '../../common/services/utils.service';
import { UsersService } from '../../users/services/users.service';
import {
  CreateCardAnalytics,
  CreateCardAppConfig,
  CreateCardCredential,
  CreateCardDocker,
  CreateCardInfo,
  CreateCardMarketplace,
  CreateCardModel,
  CreateCardNetwork,
  CreateCardSecret,
  CreateCardTelemetry,
  CreateCardVolumes,
  defaultCardPosition
} from '../device-detail/components/device-detail-common';
import { detailDeviceLabels } from '../device-detail/devices-detail.labels';
import { CustomData, CustomDataModel, EAgentCapabilities } from '../models/device-detail.model';
import { ActionsDeviceService } from './actions-device.service';

@Injectable({
  providedIn: 'root'
})
export class MoveCardService {
  column = 1;
  arraySpaceId: string[] = [];
  firstColumn: string[] = [];
  secondColumn: string[] = [];
  thirdColumn: string[] = [];
  fourColumn: string[] = [];
  numberColumns: number;
  labels = detailDeviceLabels;
  idCardMove: any;
  quantityCardId: number;
  defaultCardPosition: CustomData[] = defaultCardPosition;
  private $customData: BehaviorSubject<CustomDataModel> = new BehaviorSubject<CustomDataModel>(
    {} as CustomDataModel
  );

  constructor(
    private snackMessageService: SnackMessageService,
    private backEndCommunicationService: BackEndCommunicationService,
    private utilsService: UtilsService,
    private usersService: UsersService,
    private actionsDeviceService: ActionsDeviceService
  ) {}

  getCustomDataObservable(): BehaviorSubject<CustomDataModel> {
    return this.$customData;
  }

  setStoredPosition(customData: CustomDataModel) {
    this.$customData.next(customData);
  }

  public getCustomData(): void {
    this.backEndCommunicationService
      .getResourceObservable('/user/customData')
      .subscribe((response) => {
        this.setStoredPosition(response);
      });
  }

  modifyCustomData(
    storedCardPosition: CustomDataModel,
    device: DeviceModel,
    customData: CustomData
  ) {
    storedCardPosition.deviceDetails.priorities.forEach((app) => {
      if (app.id === customData.id) {
        app === customData;
      }
    });
    this.saveCustomData(storedCardPosition, device, innerWidth);
  }

  saveCustomData(customData: CustomDataModel, device: DeviceModel, internalWidth: number) {
    if (customData) {
      const arrayIds = this.generateArraySpaceIds(device);
      customData.deviceDetails.priorities.forEach((app) => {
        delete app.id;
        app.hiddenDevice = app.spaceId
          ? !arrayIds.some((id) => id === app.spaceId)
          : app.hiddenDevice;
      });
      //customData.deviceDetails.priorities = [];
      this.usersService.savePosition(customData).subscribe({
        next: (res: CustomDataModel) => {
          this.$customData.next(res);
          customData = res;
          // this.distributeApps(customData, device, innerWidth);
        },
        error: (err) => {
          this.snackMessageService.readError(err);
          console.error(err);
        }
      });
    }
  }

  resetCustomData(customData: CustomDataModel, device: DeviceModel, internalWidth: number) {
    if (customData) {
      customData.deviceDetails.priorities = [];
      this.usersService.savePosition(customData).subscribe({
        next: (res: CustomDataModel) => {
          this.$customData.next(res);
          customData = res;
          // this.distributeApps(customData, device, innerWidth);
        },
        error: (err) => {
          this.snackMessageService.readError(err);
          console.error(err);
        }
      });
    }
  }
  createLayoutNew(device: DeviceModel, customData: CustomDataModel) {
    this.getStoredPosition(device);
    this.arraySpaceId.forEach((element) => {
      const result = customData?.deviceDetails.priorities.findIndex(
        (app) => app.spaceId === element
      );
      if (result === -1) {
        this.createCard(element, device, customData);
      }
    });
  }
  createLayout(arrayDevice: DeviceModel[], device: DeviceModel, customData: CustomDataModel) {
    this.arraySpaceId = this.generateArraySpaceIds(device);
    const arrayAppIdesOld = this.generateArraySpaceIds(arrayDevice[0]);
    if (customData && JSON.stringify(this.arraySpaceId) !== JSON.stringify(arrayAppIdesOld)) {
      this.getStoredPosition(device);
    }
    this.arraySpaceId.forEach((element) => {
      const result = customData?.deviceDetails.priorities.findIndex(
        (app) => app.spaceId === element
      );
      if (result === -1) {
        this.createCard(element, device, customData);
      }
    });
  }

  createCard(id: string, device: DeviceModel, customData: CustomDataModel) {
    let result = device.spaces.find((app) => app.spaceId === id);
    if (result) {
      if (result.type === 2) {
        this.createAppModel(result, customData);
      } else if (result.type === 1) {
        this.createAppMarketplace(result, customData);
      } else {
        this.createAppDocker(result, customData);
      }
      this.distributeApps(customData, device, innerWidth);
      this.saveCustomData(customData, device, innerWidth);
    }
  }

  createAppModel(result, customData: CustomDataModel) {
    let createCardModel = CreateCardModel;
    createCardModel.id = this.actionsDeviceService.findId(result);
    createCardModel.spaceId = result.spaceId;
    if (
      customData.deviceDetails.priorities.findIndex(
        (app) => app.spaceId === createCardModel.spaceId
      ) === -1
    ) {
      customData.deviceDetails.priorities.push(createCardModel);
    }
  }

  createAppDocker(result, customData: CustomDataModel) {
    let createCardDocker = CreateCardDocker;
    createCardDocker.spaceId = result.spaceId;
    createCardDocker.id = this.actionsDeviceService.findId(result);

    if (
      customData.deviceDetails.priorities.findIndex(
        (app) => app.spaceId === createCardDocker.spaceId
      ) === -1
    ) {
      customData.deviceDetails.priorities.push(createCardDocker);
    }
  }

  createAppMarketplace(result, customData: CustomDataModel) {
    let createCardMarket = CreateCardMarketplace;
    createCardMarket.id = this.actionsDeviceService.findId(result);
    createCardMarket.spaceId = result.spaceId;
    if (
      customData.deviceDetails.priorities.findIndex(
        (app) => app.spaceId === createCardMarket.spaceId
      ) === -1
    ) {
      customData.deviceDetails.priorities.push(createCardMarket);
    }
  }

  distributeApps(customData: CustomDataModel, device: DeviceModel, innerWidth: number): any {
    let isInstall = false;
    this.column = 1;
    this.preparedColumn(innerWidth);
    this.arraySpaceId = this.generateArraySpaceIds(device);
    customData.deviceDetails.priorities.forEach((app, index) => {
      app = this.prepareData(app);
      this.resetColumn();
      switch (app.type) {
        case 'native':
          isInstall = this.isAppInstall(app.spaceId, device);
          if (isInstall) {
            app.hiddenDevice = false;
            app.spaceId = 'native';
            this.distributionApps(app, this.column);
            this.column++;
          } else {
            app.hiddenDevice = true;
            this.distributionApps(app, this.column);
          }
          break;
        case 'docker':
        case 'marketplace':
          isInstall = this.isAppInstall(app.spaceId, device);
          if (isInstall) {
            let appDocker = { ...app };
            appDocker.hiddenDevice = false;
            app.hiddenDevice = false;
            appDocker.spaceId = app.spaceId;
            this.distributionApps(appDocker, this.column);
            this.column++;
          } else {
            app.hiddenDevice = true;
            this.distributionApps(app, this.column);
          }
          break;
        case 'volumes':
          device.agentCapabilities.includes(EAgentCapabilities.VOLUMES)
            ? app.hidden === true
              ? (app.hiddenDevice = true)
              : (app.hiddenDevice = false)
            : (app.hiddenDevice = true);
          this.distributionApps(app, this.column);
          if (app.hiddenDevice === false) {
            this.column++;
          }
          break;
        case 'model':
          isInstall = this.isAppInstall(app.spaceId, device);
          if (isInstall) {
            let appDocker = { ...app };
            appDocker.hiddenDevice = false;
            appDocker.spaceId = app.spaceId;
            app.hiddenDevice = false;
            this.distributionApps(appDocker, this.column);
            this.column++;
          } else {
            app.hiddenDevice = true;
            this.distributionApps(app, this.column);
          }
          break;
        default:
          if (!app.hidden) {
            app.hiddenDevice = false;
            this.distributionApps(app, this.column);
            this.column++;
          } else {
            app.hiddenDevice = true;
            this.distributionApps(app, this.column);
          }
          break;
      }
    });
    return [customData, this.firstColumn, this.secondColumn, this.thirdColumn, this.fourColumn];
  }

  distributionApps(app, column) {
    switch (column) {
      case 1:
        this.firstColumn.push(app);
        break;
      case 2:
        this.secondColumn.push(app);
        break;
      case 3:
        this.thirdColumn.push(app);
        break;
      case 4:
        this.fourColumn.push(app);
        break;
    }
  }

  // quantityAppsIdDocker(id: string, device: DeviceModel) {
  //   let result = device.spaces.find((app) => app.spaceId === id && app.current.isInstalled);
  //   return { quality: result.length, apps: result };
  // }

  preparedColumn(innerWidth: number) {
    this.numberColumns = 1;
    let firstColumnIndex = 1;
    let secondColumnIndex = 2;
    let thirdColumnIndex = 3;
    let fourColumnIndex = 4;

    this.firstColumn = [];
    this.secondColumn = [];
    this.thirdColumn = [];
    this.fourColumn = [];

    if (innerWidth >= 651 && innerWidth <= 1279) {
      this.numberColumns = 2;
      secondColumnIndex = 2;
    }
    if (innerWidth >= 1280 && innerWidth <= 1799) {
      this.numberColumns = 3;
      secondColumnIndex = 2;
      thirdColumnIndex = 3;
    }
    if (innerWidth >= 1800) {
      this.numberColumns = 4;
      secondColumnIndex = 2;
      thirdColumnIndex = 3;
      fourColumnIndex = 4;
    }
    return this.numberColumns;
  }

  generateArraySpaceIds(data: any): string[] {
    const arraySpaceId: string[] = [];

    if (data.spaces.length > 0) {
      data.spaces.forEach((app) => {
        if (app.current) {
          if (app.current.isInstalled) {
            if (!app.current.isCustom) {
              arraySpaceId.push(app.spaceId);
            } else {
              if (app.current.marketApplicationId) {
                arraySpaceId.push(app.spaceId);
              } else {
                arraySpaceId.push(app.spaceId);
              }
            }
          }
        }
      });
    }
    return arraySpaceId;
  }

  isAppInstall(id: string, device: DeviceModel): boolean {
    return device.spaces.some((space) => space.spaceId === id && space.current.isInstalled);
  }

  resetColumn() {
    if (this.column > this.numberColumns) {
      this.column = 1;
    }
  }

  prepareData(app: CustomData): CustomData {
    switch (app.type) {
      case 'info':
        app.type = 'info';
        break;
      case 'config':
        app.type = 'config';
        break;
      case 'native':
        app.type = 'native';
        break;
      case 'docker':
        app.type = 'docker';
        break;
      case 'marketplace':
        app.type = 'marketplace';
        break;
      case 'model':
        app.type = 'model';
        break;
      case 'network':
        app.type = 'network';
        break;
      case 'telemetry':
        app.type = 'telemetry';
        break;
      case 'analytics':
        app.type = 'analytics';
        break;
      case 'credentials':
        app.type = 'credentials';
        break;
      case 'secrets':
        app.type = 'secrets';
        break;
      case 'volumes':
        app.type = 'volumes';
        break;
    }
    app.data = {};

    return app;
  }

  public saveExpand(appModifiy: any, customData: CustomDataModel, device: DeviceModel): void {
    let result = [];
    this.firstColumn = this.modifyExpandAllColumn(result, appModifiy.event, this.firstColumn);
    this.secondColumn = this.modifyExpandAllColumn(result, appModifiy.event, this.secondColumn);
    this.thirdColumn = this.modifyExpandAllColumn(result, appModifiy.event, this.thirdColumn);
    this.fourColumn = this.modifyExpandAllColumn(result, appModifiy.event, this.fourColumn);

    this.savePosition(appModifiy.mode, customData, device);
  }

  modifyExpandAllColumn(result, appModifiy, column) {
    column.forEach((app) => {
      if (
        app.type === 'docker' ||
        app.type === 'native' ||
        app.type === 'marketplace' ||
        app.type === 'model'
      ) {
        if (app.spaceId === appModifiy.spaceId) {
          app.expanded = appModifiy.expanded;
        }
      } else {
        if (app.title === appModifiy.title) {
          app.expanded = appModifiy.expanded;
          app.hidden = appModifiy.hidden;
        }
      }
    });
    return column;
  }

  quantityAppShowColum(column): number {
    let arrayAppShow = [];
    column.forEach((app, index) => {
      if (app.spaceId) {
        if (this.arraySpaceId.some((appId) => appId === app.spaceId)) {
          arrayAppShow.push(app);
        }
      } else {
        arrayAppShow.push(app);
      }
    });
    return arrayAppShow.length;
  }

  formPriorityArray(priorities, column, i): [] {
    while (column[i] && column[i].hiddenDevice) {
      if (
        priorities.length !== 0 &&
        (column[i].type === 'docker' ||
          column[i].type === 'native' ||
          column[i].type === 'marketplace' ||
          column[i].type === 'model')
      ) {
        if (this.idCardMove?.id === column[i].id && this.quantityCardId > 1) {
          if (this.idCardMove.spaceId === column[i].spaceId) {
            priorities.push(column[i]);
          }
        } else {
          const idx = priorities.findIndex((app) => app.spaceId === column[i].spaceId);
          if (idx === -1) {
            priorities.push(column[i]);
          }
          column.splice(i, 1);
        }
      } else {
        priorities.push(column[i]);
        column.splice(i, 1);
      }
    }
    if (column[i]) {
      if (
        priorities.length !== 0 &&
        (column[i].type === 'docker' ||
          column[i].type === 'marketplace' ||
          column[i].type === 'model' ||
          column[i].type === 'native')
      ) {
        if (this.idCardMove?.id === column[i].spaceId && this.quantityCardId > 1) {
          if (this.idCardMove.spaceId === column[i].spaceId) {
            priorities.push(column[i]);
          }
        } else {
          const idx = priorities.findIndex((app) => app.spaceId === column[i].spaceId);
          if (idx === -1) {
            priorities.push(column[i]);
          }
        }
      } else {
        priorities.push(column[i]);
      }
    }
    return priorities;
  }

  public prepareDrop(device: DeviceModel): void {
    this.getStoredPosition(device);
  }

  generateAppInfo(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardInfo);
  }

  generateAppTelemetry(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardTelemetry);
  }

  generateAppCredential(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardCredential);
  }

  generateAppVolumes(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardVolumes);
  }

  generateAppSecret(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardSecret);
  }

  generateAppNetwork(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardNetwork);
  }

  generateAppconfig(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardAppConfig);
  }

  generateAppAnalytics(customData: CustomDataModel): void {
    customData.deviceDetails.priorities.push(CreateCardAnalytics);
  }

  public savePosition(action: string, customData: CustomDataModel, device: DeviceModel): void {
    const cardShowColumnOne = this.quantityAppShowColum(this.firstColumn);
    const cardShowColumnTwo = this.quantityAppShowColum(this.secondColumn);
    const cardShowColumnThird = this.quantityAppShowColum(this.thirdColumn);
    const cardShowColumnFour = this.quantityAppShowColum(this.fourColumn);
    const maxCardInColumn = Math.max(
      cardShowColumnOne,
      cardShowColumnTwo,
      cardShowColumnThird,
      cardShowColumnFour
    );

    let priorities = [];
    for (let i = 0; i < maxCardInColumn; i++) {
      if (this.firstColumn[i]) {
        priorities = this.formPriorityArray(priorities, this.firstColumn, i);
      }
      if (this.secondColumn[i]) {
        priorities = this.formPriorityArray(priorities, this.secondColumn, i);
      }
      if (this.thirdColumn[i]) {
        priorities = this.formPriorityArray(priorities, this.thirdColumn, i);
      }
      if (this.fourColumn[i]) {
        priorities = this.formPriorityArray(priorities, this.fourColumn, i);
      }
    }
    customData.deviceDetails.priorities = priorities;

    if (action !== 'expand') {
      this.saveCustomData(customData, device, innerWidth);
    } else {
      this.distributeApps(customData, device, innerWidth);
      this.saveCustomData(customData, device, innerWidth);
      this.utilsService.setCacheExpirationInSeconds(2);
    }
  }

  public getStoredPosition(device: DeviceModel): void {
    this.getCustomDataObservable().subscribe(
      (res: any) => {
        let customData = res;
        //console.log(res);
        if (res.deviceDetails.priorities.length === 0) {
          customData.deviceDetails.priorities = this.defaultCardPosition;
        } else {
          const result = [];
          res.deviceDetails.priorities.forEach((app) => {
            result.push(app.type);
          });

          !result.includes('config') ? this.generateAppconfig(customData) : null;
          !result.includes('info') ? this.generateAppInfo(customData) : null;
          !result.includes('analytics') ? this.generateAppAnalytics(customData) : null;
          !result.includes('telemetry') ? this.generateAppTelemetry(customData) : null;
          !result.includes('network') ? this.generateAppNetwork(customData) : null;
          !result.includes('credentials') ? this.generateAppCredential(customData) : null;
          !result.includes('secrets') ? this.generateAppSecret(customData) : null;
          !result.includes('volumes') ? this.generateAppVolumes(customData) : null;
        }

        this.distributeApps(customData, device, innerWidth);
      },
      (error) => {
        this.snackMessageService.readError(error);
        //console.log(error);
      }
    );
  }
}
