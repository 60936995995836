import { Injectable } from '@angular/core';
import { BackEndCommunicationService } from 'app/main/common/services/beComm.service';
import { BehaviorSubject, forkJoin, from, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppsService } from '../../apps/services/apps.service';
import { DeviceModel, PaginatorTable } from '../../common/models/common.model';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { StorageService } from '../../common/services/storage.service';
import { UtilsService } from '../../common/services/utils.service';
import { DeviceStoreService } from '../../store/device-store.service';
import { DeviceConfigModel, NetworkConfigModel } from '../models/batch.model';

import {
  AssetsModel,
  DockerCredentialModel,
  Log,
  SecretModel,
  Telemetry
} from '../models/device-detail.model';

interface DeviceCache {
  data?: DeviceModel;
  lastUpdate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ActionsDeviceService {
  onDevicesChanged: BehaviorSubject<any>;
  devices: DeviceModel[];
  devicePaginator: PaginatorTable;
  private deviceCache = {} as DeviceCache;
  private deletedDevices = new Subject<void>();
  public deviceRemoved$ = this.deletedDevices.asObservable();
  socketStatus: boolean;

  constructor(
    private backEndCommunicationService: BackEndCommunicationService,
    private deviceStore: DeviceStoreService,
    private appsService: AppsService,
    private snackMessageService: SnackMessageService,
    private utilsService: UtilsService,
    private storageService: StorageService
  ) {
    this.onDevicesChanged = new BehaviorSubject([]);
  }

  exportSecrets(deviceId: string): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/secrets/export'
    );
  }

  getListTags(): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable('/devices/tags');
  }
  getProcessOnly(deviceId: string, time: string, from: string, to?: string) {
    let urlProcess: string = '';
    if (deviceId) {
      urlProcess += `/devices/${deviceId}/telemetries/process?`;
    }
    if (time) {
      urlProcess += `time=${time}&`;
    }
    if (from) {
      urlProcess += `from=${from}&`;
    }
    if (to) {
      urlProcess += `to=${to}&`;
    }

    return this.backEndCommunicationService.getResourceObservable(urlProcess);
  }

  getAnalytics(
    deviceId: string,
    time: string,
    from: string,
    metric?: string,
    source?: string,
    to?: string
  ): Observable<any> {
    let urlTelemetries: string = '';
    if (deviceId) {
      urlTelemetries += `/devices/${deviceId}/telemetries?`;
    }
    if (from) {
      urlTelemetries += `from=${from}&`;
    }
    if (time) {
      urlTelemetries += `time=${time}&`;
    }
    if (to) {
      urlTelemetries += `to=${to}&`;
    }
    if (metric) {
      urlTelemetries += `metric=${metric}&`;
    }
    if (source) {
      urlTelemetries += `sources=${source}&`;
    }
    if (from) {
      return this.backEndCommunicationService.getResourceObservable(urlTelemetries);
    }
  }

  exportCredencials(deviceId: string): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/dockercredentials/export'
    );
  }

  activeStandalone(deviceId: string, data: any): Observable<any> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/standalone/enable',
      data
    );
  }

  deactiveStandalone(deviceId: string): Observable<any> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/standalone/disable',
      { rollback: true }
    );
  }

  createVolume(deviceId: string, volume: any): Observable<any> {
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/docker/volumes`,
      volume
    );
  }

  deleteVolume(deviceId: string, volumeId: string): Observable<any> {
    return this.backEndCommunicationService.deleteResourceObservable(
      `/devices/${deviceId}/docker/volumes/${volumeId}`
    );
  }

  public shutDownDevice = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/poweroff',
      null
    );
  };
  public activateProxy = (deviceId: string, data): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/proxy/enable',
      data
    );
  };
  public deactivateProxy = (deviceId: string, rollback: boolean): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/proxy/disable',
      { rollback }
    );
  };
  public brickDevice = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/brick',
      null
    );
  };

  public restartDevice = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/reboot',
      null
    );
  };

  public provisionDevice = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/provision',
      null
    );
  };
  public deprovisionDevice = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/deprovision',
      null
    );
  };

  public deleteDevice(deviceId: string): Observable<void> {
    return this.backEndCommunicationService.deleteResourceObservable('/devices/' + deviceId);
  }

  public sendOtaToDevice = (deviceId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource('/devices/' + deviceId + '/ota', {})
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public sendOtaToDeviceObservable = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/ota',
      {}
    );
  };

  stopBuildDockerApplicationFromDevice(nodoId: string, workloadsId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + nodoId + '/workloads/' + workloadsId + '/stopbuild',
      {}
    );
  }

  public updateDeviceInfo(deviceId: string): void {
    if (deviceId) {
      deviceId = this.storageService.getDeviceId();
    }
    if (deviceId !== 'noData') {
      this.utilsService.getDeviceCache(deviceId);
    }
  }

  findId(result) {
    if (result.applicationId) {
      return result.applicationId;
    } else if (result.current.applicationId) {
      return result.current.applicationId._id;
    } else if (result.current.modelApplicationId) {
      return result.current.modelApplicationId._id;
    } else {
      return result.current.marketApplicationId._id;
    }
  }

  generateDataApp(data, device) {
    let dataApp = {
      environmentVariables: [],
      name: data.versionName + Date.now(),
      appConfig: data.jsonConfig || {},
      applicationId: data.applicationId,
      appVersionId: data.appVersionId,
      runDocker: data.runAutomatically,
      forcePull: data.forceDocker,
      enableLogs: data.enabledLogs ?? data.logsEnabled
    };
    const dataEnv = [];
    data.enviromentVariables.forEach((env, index) => {
      dataEnv.push({ name: env.name, value: data.formMultiple.value.secret[index][env.name] });
    });
    dataEnv.forEach((env) => {
      if (typeof env.value === 'boolean' || typeof env.value === 'number') {
        env.value = env.value.toString();
      }
      env.value = this.utilsService.conversorBase64(env.value);
      env.name = this.utilsService.conversorBase64(env.name);
    });
    dataApp.environmentVariables = dataEnv;
    return dataApp;
  }

  installOrUpdateAppMarketplace(data, device, mode, spaceId?) {
    let dataApp = this.generateDataApp(data, device);
    if (mode === 'install') {
      this.appsService.createAppMarketplace(dataApp, device._id).subscribe({
        error: (error) => {
          this.snackMessageService.readError(error);
          // console.log(error);
        }
      });
    } else {
      dataApp['spaceId'] = spaceId;
      this.appsService.updateAppMarketplace(dataApp, device._id, spaceId).subscribe({
        error: (error) => {
          this.snackMessageService.readError(error);
          //console.log(error);
        }
      });
    }
  }

  installOrUpdateModel(data, device, mode, spaceId?) {
    //console.log(data);
    let dataApp = this.generateDataModel(data, device);
    if (mode === 'install') {
      this.appsService.createModel(device._id, dataApp).subscribe({
        error: (error) => {
          //console.log(error);
          this.snackMessageService.readError(error);
        }
      });
    } else {
      dataApp['spaceId'] = spaceId;
      this.appsService.updateAppModel(dataApp, device._id, spaceId).subscribe({
        error: (error) => {
          //console.log(error);
          this.snackMessageService.readError(error);
        }
      });
    }
  }

  generateDataModel(data, device) {
    let dataApp = {
      environmentVariables: [],
      name: data.versionName + Date.now(),
      architectures: data.versionData.architectures,
      appConfig: data.jsonConfig || {},
      applicationId: data.applicationId,
      appVersionId: data.appVersionId,
      enableLogs: data.enabledLogs,
      forcePull: data.forceDocker,
      runDocker: data.runAutomatically
    };
    const dataEnv = [];
    data.enviromentVariables.forEach((env, index) => {
      dataEnv.push({ name: env.name, value: data.formMultiple.value.secret[index][env.name] });
    });
    dataEnv.forEach((env) => {
      if (typeof env.value === 'boolean' || typeof env.value === 'number') {
        env.value = env.value.toString();
      }
      env.value = this.utilsService.conversorBase64(env.value);
      env.name = this.utilsService.conversorBase64(env.name);
    });
    dataApp.environmentVariables = dataEnv;
    return dataApp;
  }

  pruneVolumenes(deviceId) {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/docker/actions/prunevolumes',
      {}
    );
  }

  pruneNetwork(deviceId: string) {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/docker/actions/prunenetworks',
      {}
    );
  }

  pruneImages(deviceId: string) {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/docker/actions/pruneimages',
      {}
    );
  }

  pruneContainers(deviceId: string) {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/docker/actions/prunecontainers',
      {}
    );
  }

  postSecretsObservable(deviceId: string, secret: [{ value; name }]): Observable<SecretModel> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/secrets',
      { data: { secrets: secret } }
    );
  }

  parseEnv(deviceId: string, file: any): Observable<any> {
    return this.backEndCommunicationService.postZipResourceObservable(
      '/devices/' + deviceId + '/secrets/file',
      file
    );
  }

  parseCsv(deviceId: string, file: any): Observable<any> {
    return this.backEndCommunicationService.postZipResourceObservable(
      '/devices/' + deviceId + '/dockercredentials/file',
      file
    );
  }

  deleteAllSecrets(deviceId: string): Observable<any> {
    return this.backEndCommunicationService.deleteResourceObservable(
      '/devices/' + deviceId + '/secrets',
      {}
    );
  }

  deleteAllCredentials(deviceId: string): Observable<string> {
    return this.backEndCommunicationService.deleteResourceObservable(
      '/devices/' + deviceId + '/dockercredentials',
      {}
    );
  }

  postCredentialsObservable(
    deviceId: string,
    credentials: [{ value; name }]
  ): Observable<DockerCredentialModel> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/dockerCredentials',
      { data: { credentials } }
    );
  }

  getSecretById(deviceId: string, secretId): Observable<SecretModel> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/secrets/' + secretId
    );
  }

  getCredentialById(deviceId: string, credentialId): Observable<DockerCredentialModel> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/dockerCredentials/' + credentialId
    );
  }

  deleteSecretObservable(deviceId: string, id): Observable<string> {
    return this.backEndCommunicationService.deleteResourceObservable(
      '/devices/' + deviceId + '/secrets/' + id,
      {}
    );
  }

  deleteCredentialObservable(deviceId: string, id): Observable<string> {
    return this.backEndCommunicationService.deleteResourceObservable(
      '/devices/' + deviceId + '/dockerCredentials/' + id,
      {}
    );
  }

  pruneAll(deviceId: string): Observable<string> {
    const pruneContainers$ = this.pruneContainers(deviceId);
    const pruneImages$ = this.pruneImages(deviceId);
    const pruneVolumes$ = this.pruneVolumenes(deviceId);
    const pruneNetwork$ = this.pruneNetwork(deviceId);

    return forkJoin([pruneContainers$, pruneImages$, pruneVolumes$, pruneNetwork$]).pipe(
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      }),
      map(() => 'success')
    );
  }

  public getDeviceAppConfig(
    deviceId: string
  ): Promise<{ lastUpdate: Date; name: string; config: string }> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .getResource('/devices/' + deviceId + '/appconfig')
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getDeviceAppLogsExport(
    deviceId: string,
    from?: string,
    results?: string,
    to?: string,
    search?: string
  ): Promise<Array<Log>> {
    return new Promise((resolve, reject) => {
      let url = '/devices/' + deviceId + '/applogs/export?';
      if (from) {
        url = url + 'from=' + from;
      }
      if (results) {
        url = url + '&results=' + results;
      }
      if (to) {
        url = url + '&to=' + to;
      }
      if (search) {
        url = url + 'search=' + search;
      }
      this.backEndCommunicationService
        .getResource(url)
        .then((response: Array<Log>) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteAppLogs(deviceId: string): Observable<void> {
    return this.backEndCommunicationService.deleteResourceObservable(
      '/devices/' + deviceId + '/applogs'
    );
  }

  public getDeviceDockerLogsExport(
    deviceId: string,
    workloadsId: string,
    from?: string,
    results?: string,
    to?: string,
    search?: string
  ): Observable<Array<Log>> {
    let url = `/devices/${deviceId}/workloads/${workloadsId}/logs/export?`;
    if (from) {
      url = url + 'from=' + from;
    }
    if (results) {
      url = url + '&results=' + results;
    }
    if (to) {
      url = url + '&to=' + to;
    }
    if (search) {
      url = url + 'search=' + search;
    }
    return this.backEndCommunicationService.getResourceObservable(url);
  }

  public getDeviceDockerLogs(
    deviceId: string,
    workloadsId: string,
    from?: string,
    results?: string,
    to?: string,
    search?: string
  ): Observable<Array<Log>> {
    let url = `/devices/${deviceId}/workloads/${workloadsId}/logs?`;
    if (from) {
      url = url + 'from=' + from;
    }
    if (results) {
      url = url + '&results=' + results;
    }
    if (to) {
      url = url + '&to=' + to;
    }
    if (search) {
      url = url + 'search=' + search;
    }
    return this.backEndCommunicationService.getResourceObservable(url);
  }

  public deleteDockerLogs(deviceId: string, workloadsId: string): Observable<void> {
    return this.backEndCommunicationService.deleteResourceObservable(
      `/devices/${deviceId}/workloads/${workloadsId}/logs`
    );
  }

  public sendAppConfigToDevice(deviceId: string, appConfig: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const sendObject = JSON.parse(appConfig);
      this.backEndCommunicationService
        .postResource('/devices/' + deviceId + '/appconfig', sendObject)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendAppConfigDocker(deviceId: string, workloadsId, appConfig: string): Observable<void> {
    const sendObject = {
      appConfig: appConfig
    };
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/workloads/${workloadsId}/appconfig`,
      sendObject
    );
  }

  sendAppConfigApp(deviceId, config): Promise<void> {
    const sendObject = {
      data: JSON.parse(config.toString())
    };
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource(`/devices/${deviceId}/nativeappconfig`, sendObject)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public restartDockerDaemon(deviceId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/docker/actions/restartdaemon',
      {}
    );
  }

  public deletePersistentSizeFromDevice(deviceId: string, workloadsId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/devices/' + deviceId + '/workloads/' + workloadsId + '/persist')
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public postContainerInfo(
    deviceId: string,
    workloadsId: string,
    capabilityNumber: number,
    action?: string
  ): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/workloads/${workloadsId}/info`,
      { capability: capabilityNumber, action: action }
    );
  }

  deleteSpaceDocker(deviceId, workloadsId) {
    return from(
      this.backEndCommunicationService.deleteResource(
        '/devices/' + deviceId + '/user/workloads/' + workloadsId
      )
    );
  }

  public stopDocker(deviceId: string, workloadsId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/workloads/' + workloadsId + '/stop',
      {}
    );
  }

  public startDocker(deviceId: string, workloadsId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/workloads/' + workloadsId + '/start',
      {}
    );
  }

  public startApp(deviceId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/startapps',
      {}
    );
  }

  public sendIncrementalAgentToDevice = (deviceId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource(`/devices/${deviceId}/incrementalagent`, {})
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public sendFullUpdateToDevice = (deviceId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource('/devices/' + deviceId + '/full', {})
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public sendFullUpdateToDeviceObservable = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/full',
      {}
    );
  };
  public sendFullAgentUpdateToDevice = (deviceId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource(`/devices/${deviceId}/fullagent`, {})
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public sendFullAgentUpdateToDeviceObservable = (deviceId: string): Observable<void> => {
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/fullagent`,
      {}
    );
  };

  public stopApp(deviceId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/stopapps',
      {}
    );
  }

  public deletePersistSizeApp(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/devices/' + deviceId + '/actions/applicationpersistent')
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPersistSizeAppFromDevice(deviceId: string): Observable<void> {
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/actions/applicationpersistent',
      {}
    );
  }

  public getDevice = (deviceId: string): Observable<DeviceModel> => {
    this.deviceCache.lastUpdate = new Date();
    return from(
      this.backEndCommunicationService.getResource('/devices/' + deviceId).then(
        (response: any) => {
          this.deviceCache.data = response;
          this.deviceStore.setDevice(response);
          return response;
        },
        (error: any) => {
          return error;
        }
      )
    );
  };

  createPeer(deviceId: string, companyId?: string) {
    // return from('done');
    return from(this.backEndCommunicationService.postResource(`/devices/${deviceId}/vpnpeer`, {}));
  }

  public updateDeviceTags = (deviceId: string, tags: string[]): Observable<void> => {
    return this.backEndCommunicationService.putResourceObservable(
      '/devices/' + deviceId + '/tags',
      {
        data: { tags: tags }
      }
    );
  };
  public updateDeviceAssignedName = (
    deviceId: string,
    assignedName: string
  ): Observable<string> => {
    return this.backEndCommunicationService.putResourceObservable(
      '/devices/' + deviceId + '/name',
      {
        data: { name: assignedName }
      }
    );
  };

  public getNetworkConfig(deviceId: string): Promise<NetworkConfigModel> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .getResource('/devices/' + deviceId + '/networks')
        .then((response: NetworkConfigModel) => {
          resolve(response);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  public updateNetworkConfig(
    deviceId: string,
    networkConfiguration: NetworkConfigModel
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .putResource('/devices/' + deviceId + '/networks', {
          data: networkConfiguration
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getAllDeviceInverse(
    groupId: string,
    from: number,
    size: number,
    filter: string,
    search = ''
  ): any {
    return this.backEndCommunicationService.getResource(
      `/devices/group/${groupId}/inverse/customfilter?from=${from}&size=${size}&filter=${filter}&search=${search}`
    );
  }

  public getAllDevice(
    fromSize: number,
    size: number,
    sortColumn = '',
    sortOrder = '',
    filter: string,
    search = '',
    group = ''
  ): Observable<any> {
    return from(
      this.backEndCommunicationService.getResource(
        `/devices/customfilter?from=${fromSize}&size=${size}&filter=${filter}&group=${group}&search=${search}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`
      )
    ).pipe(
      map((response: any) => {
        this.devices = response.devicesCustom;
        this.devicePaginator = {
          hasNextPage: response.hasNextPage,
          hasPrevPage: response.hasPrevPage,
          limit: response.limit,
          nextPage: response.nextPage,
          page: response.page,
          pagingCounter: response.pagingCounter,
          prevPage: response.prevPage,
          totalPages: response.totalPages,
          totalDocs: response.total
        };
        response = {};
        response.deviceList = this.devices;
        response.devicePaginator = this.devicePaginator;
        // console.log(response);
        /*
        response.map((device) => {
          device.activated === true
            ? (device.tagProvisioned = 'Prov')
            : (device.tagProvisioned = 'Dep');
        });
        */
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getProvisionedDevicesList(): Promise<DeviceModel[]> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .getResource('/devices')
        .then((response: any) => {
          this.devices = response;
          response.map((device) => {
            if (device.activated === true) {
              device.tagProvisioned = 'Prov';
            } else {
              device.tagProvisioned = 'Dep';
            }
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteDeviceList(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/devices/' + deviceId)
        .then((response: any) => {
          this.onDevicesChanged.next(this.devices);
          this.deletedDevices.next();
          resolve(response);
        })
        .catch((error) => {
          reject('Error deleting devices');
        });
    });
  }

  public deprovisionDeviceList = (deviceId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource('/devices/' + deviceId + '/actions/deprovision', null)
        .then((response: any) => {
          this.onDevicesChanged.next(this.devices);
          resolve(response);
        })
        .catch((error) => {
          reject('Error deprovisioning device');
        });
    });
  };

  public provisionDeviceList = (deviceId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postResource('/devices/' + deviceId + 'actions/provision', null)
        .then((response: any) => {
          this.onDevicesChanged.next(this.devices);
          resolve(response);
        })
        .catch((error) => {
          reject('Error provisioning device');
        });
    });
  };

  public getDeviceAssets(deviceId: string): Observable<Array<AssetsModel>> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/assets'
    );
  }

  public uploadDeviceAssetWithProgress = (deviceId: string, asset: any): Observable<any> => {
    const formData: FormData = new FormData();
    formData.append('data', '{ "asset": "' + asset.name + '" }');
    formData.set('file', asset);
    return this.backEndCommunicationService.postZipResourceWithProgress(
      '/devices/' + deviceId + '/assets',
      formData
    );
  };

  public deleteDeviceAssets(deviceId: string, asset: AssetsModel): Observable<void> {
    const dataDelete = { data: { asset: asset } };
    return this.backEndCommunicationService.deleteResourceObservable(
      '/devices/' + deviceId + '/assets',
      dataDelete
    );
  }

  public getDevicePicture(deviceId: string): Observable<string> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/picture'
    );
  }

  public updateDevicePicture(deviceId: string, image: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('data', '{ "picture": "' + image.name + '" }');
      formData.set('file', image);
      this.backEndCommunicationService
        .putZipResource('/devices/' + deviceId + '/picture', formData)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateDeviceGps = (
    deviceId: string,
    gps: { lat: number; lng: number }
  ): Observable<DeviceModel> => {
    return from(
      this.backEndCommunicationService.putResource('/devices/' + deviceId + '/gps', {
        data: { gps: gps }
      })
    );
  };

  public deleteOverloadNative(deviceId: string): Observable<any> {
    return from(
      this.backEndCommunicationService.deleteResource(
        '/devices/' + deviceId + '/state/appLogWarning'
      )
    );
  }

  requestTimeDevice(deviceId: string) {
    return from(
      this.backEndCommunicationService.postResource(`/devices/${deviceId}/actions/updatedate`, {})
    );
  }

  deleteTimeDevice(deviceId: string) {
    return from(
      this.backEndCommunicationService.putResource(`/devices/${deviceId}/actions/updatedate`, {})
    );
  }

  public deleteOverloadDocker(deviceId: string): Observable<any> {
    return from(
      this.backEndCommunicationService.deleteResource(
        '/devices/' + deviceId + '/state/dockerLogWarning'
      )
    );
  }

  public getDeviceName(deviceId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .getResource('/devices/' + deviceId)
        .then((response: any) => {
          resolve(response.deviceName);
        })
        .catch((error) => {
          reject('Error getting device name');
        });
    });
  }

  public registerDevices(devicesObject: any): Observable<void> {
    const dataObject = {
      data: devicesObject
    };
    return this.backEndCommunicationService.postResourceObservable('/devices', dataObject);
  }

  public deleteDevicePicture(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/devices/' + deviceId + '/picture')
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  startVpn(deviceId: string) {
    return from(
      this.backEndCommunicationService
        .postResource(`/devices/${deviceId}/actions/startvpns`, {})
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          return error;
        })
    );
  }

  stopVpn(deviceId: string) {
    return from(
      this.backEndCommunicationService.postResource(`/devices/${deviceId}/actions/stopvpns`, {})
    );
  }

  public getDeviceConfig(deviceId: string): Promise<DeviceConfigModel> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .getResource('/devices/' + deviceId + '/devconfig')
        .then((response: any) => {
          const config_default = {
            lastUpdate: response.lastUpdate,
            config: {
              telemetryLatency: 240,
              appLogs: false,
              dockerLogs: false,
              ackLogs: false
            }
          };

          if (response.config.length === 0) {
            resolve(config_default);
          } else {
            const deviceConfig: DeviceConfigModel = {
              lastUpdate: response.lastUpdate,
              config: JSON.parse(response.config)
            };
            resolve(deviceConfig);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  regeneratePsk(deviceId: string) {
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/standalone/auth/renew`,
      {}
    );
  }

  public getTelemetryDeviceConfig(deviceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .getResource('/devices/' + deviceId + '/devconfig/telemetrylatency')
        .then((response: any) => {
          const respObject: any = response;
          if (Object.keys(respObject).length === 0) {
            resolve(240);
          } else {
            const latencyNumber = parseInt(respObject.telemetryLatency, 10);
            resolve(latencyNumber);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getDeviceLastTelemetryProcessesArray = (deviceId: string): Observable<Telemetry> => {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/telemetries/last'
    );
  };

  public getTelemetryWithDockerDinamics(deviceId: string): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable(
      '/devices/' + deviceId + '/telemetrylatency'
    );
  }

  public updateTelemetryDeviceConfig(deviceId: string, telemetryLatency: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const sendObject = {
        data: {
          config: {
            telemetryLatency: telemetryLatency
          }
        }
      };
      this.backEndCommunicationService
        .postResource('/devices/' + deviceId + '/devconfig/telemetrylatency', sendObject)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateTelemetryWithDockerDinamic(
    deviceId: string,
    telemetryLatency: number
  ): Observable<void> {
    const sendObject = {
      telemetryLatency: telemetryLatency
    };
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/telemetrylatency',
      sendObject
    );
  }

  public enabledLogsNative(deviceId: string, enableLogs: boolean): Observable<void> {
    const sendObject = {
      enableLogs
    };
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/native/logs/enable',
      sendObject
    );
  }

  public enabledLogsDocker(
    deviceId: string,
    enableLogs: boolean,
    workloadsId: string
  ): Observable<void> {
    const sendObject = {
      enableLogs
    };
    return this.backEndCommunicationService.postResourceObservable(
      '/devices/' + deviceId + '/workloads/' + workloadsId + '/logs/enable',
      sendObject
    );
  }

  public updateSizeVolume(deviceId) {
    return this.backEndCommunicationService.getResourceObservable(
      `/devices/${deviceId}/docker/volumes/telemetries/last`
    );
  }

  public cancelPendingCommand(
    deviceId: string,
    workloadsId: string,
    action: string
  ): Observable<void> {
    const sendObject = {
      action
    };
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/workloads/${workloadsId}/cancel/${action}`,
      sendObject
    );
  }

  public clearErrorPendingCommand(
    deviceId: string,
    workloadsId: string,
    commandId: number
  ): Observable<void> {
    const sendObject = {
      commandId
    };
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/workloads/${workloadsId}/cleanerror/${commandId}`,
      sendObject
    );
  }

  public clearErrorDevice(deviceId: string, action: string): Observable<void> {
    const sendObject = {
      action
    };
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/cleanerror/${action}`,
      sendObject
    );
  }

  public cancelActionDevice(deviceId: string, action: string): Observable<void> {
    const sendObject = {
      action
    };
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/cancel/${action}`,
      sendObject
    );
  }

  createSpaceDocker(
    deviceId: string,
    appVersionId: string,
    applicationId: string,
    run: boolean,
    forceDocker: boolean,
    enableLogs: boolean,
    appConfig: string,
    appConfigId?: string
  ) {
    const sendObject = {
      appVersionId: appVersionId,
      applicationId: applicationId,
      runDocker: run,
      forcePull: forceDocker,
      enableLogs: enableLogs,
      appConfig: appConfig,
      appConfigId: appConfigId
    };
    return from(
      this.backEndCommunicationService.postResourceObservable(
        '/devices/' + deviceId + '/user/workloads',
        sendObject
      )
    );
  }

  updateSpaceDocker(
    deviceId: string,
    appVersionId: string,
    applicationId: string,
    workloadsId: string,
    runDocker: boolean,
    forceDocker: boolean,
    enableLogs: boolean,
    appConfig: string,
    appConfigId?: string
  ) {
    const sendObject = {
      appVersionId: appVersionId,
      applicationId: applicationId,
      runDocker: runDocker,
      forcePull: forceDocker,
      enableLogs: enableLogs,
      appConfig: appConfig,
      appConfigId: appConfigId
    };
    return from(
      this.backEndCommunicationService.putResourceObservable(
        `/devices/${deviceId}/user/workloads/${workloadsId}`,
        sendObject
      )
    );
  }

  createSpaceMarketplace(
    deviceId: string,
    appVersionId: string,
    applicationId: string,
    runDocker: boolean,
    forceDocker: boolean,
    enableLogs: boolean,
    appConfig: string,
    services?: {
      name: string;
      ports: [{ name: string; value: string }];
      env: [{ name: string; value: string }];
      volumes: [{ name: string; value: string }];
    }[],
    appConfigId?: string
  ) {
    const sendObject = {
      appVersionId: appVersionId,
      applicationId: applicationId,
      runDocker: runDocker,
      forcePull: forceDocker,
      enableLogs: enableLogs,
      appConfig: appConfig,
      services: services,
      appConfigId: appConfigId
    };
    return from(
      this.backEndCommunicationService.postResourceObservable(
        '/devices/' + deviceId + '/market/workloads',
        sendObject
      )
    );
  }

  updateSpaceMarketplace(
    deviceId: string,
    appVersionId: string,
    applicationId: string,
    workloadsId: string,
    runDocker: boolean,
    forceDocker: boolean,
    enableLogs: boolean,
    appConfig: string,
    services?: {
      name: string;
      ports: [{ name: string; value: string }];
      env: [{ name: string; value: string }];
      volumes: [{ name: string; value: string }];
    }[],
    appConfigId?: string
  ) {
    const sendObject = {
      appVersionId: appVersionId,
      applicationId: applicationId,
      runDocker: runDocker,
      forcePull: forceDocker,
      enableLogs: enableLogs,
      appConfig: appConfig,
      appConfigId: appConfigId,
      services: services
    };
    return from(
      this.backEndCommunicationService.putResourceObservable(
        `/devices/${deviceId}/market/workloads/${workloadsId}`,
        sendObject
      )
    );
  }

  createSpaceModel(
    deviceId: string,
    appVersionId: string,
    applicationId: string,
    runDocker: boolean,
    forceDocker: boolean,
    enableLogs: boolean,
    gpu: boolean,
    appConfig?: string,
    services?: [
      {
        name: string;
        ports: [{ name: string; value: string }];
        env: [{ name: string; value: string }];
        volumes: [{ name: string; value: string }];
      }
    ],
    appConfigId?: string
  ) {
    const sendObject = {
      appVersionId: appVersionId,
      applicationId: applicationId,
      runDocker: runDocker,
      forcePull: forceDocker,
      enableLogs: enableLogs,
      gpu: gpu,
      appConfig: appConfig,
      services: services,
      appConfigId: appConfigId
    };
    return from(
      this.backEndCommunicationService.postResourceObservable(
        '/devices/' + deviceId + '/model/workloads',
        sendObject
      )
    );
  }

  updateSpaceModel(
    deviceId: string,
    appVersionId: string,
    applicationId: string,
    workloadsId: string,
    runDocker: boolean,
    forceDocker: boolean,
    enableLogs: boolean,
    gpu: boolean,
    appConfig: string,
    services?: [
      {
        name: string;
        ports: [{ name: string; value: string }];
        env: [{ name: string; value: string }];
        volumes: [{ name: string; value: string }];
      }
    ],
    appConfigId?: string
  ) {
    const sendObject = {
      appVersionId: appVersionId,
      applicationId: applicationId,
      runDocker: runDocker,
      forcePull: forceDocker,
      enableLogs: enableLogs,
      gpu: gpu,
      appConfig: appConfig,
      services: services,
      appConfigId: appConfigId
    };
    return from(
      this.backEndCommunicationService.putResourceObservable(
        `/devices/${deviceId}/model/workloads/${workloadsId}`,
        sendObject
      )
    );
  }

  updateSpaceModelServices(deviceId: string, workloadsId: string, services: any[]) {
    const sendObject = {
      services: services
    };
    return from(
      this.backEndCommunicationService.putResourceObservable(
        `/devices/${deviceId}/model/workloads/${workloadsId}/services`,
        sendObject
      )
    );
  }

  updateSpaceMarketServices(deviceId: string, workloadsId: string, services: any[]) {
    const sendObject = {
      services: services
    };
    return from(
      this.backEndCommunicationService.putResourceObservable(
        `/devices/${deviceId}/market/workloads/${workloadsId}/services`,
        sendObject
      )
    );
  }
}
