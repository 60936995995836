import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Buffer } from 'buffer';
import { Base64 } from 'js-base64';
import { Observable } from 'rxjs';
import { DeviceStoreService } from '../../store/device-store.service';
import { ModalErrorTierPermissionComponent } from '../components/modal-error-tier-permission/modal-error-tier-permission.component';
import { DeviceModel } from '../models/common.model';
import { BackEndCommunicationService } from './beComm.service';
import { SnackMessageService } from './snackMessage.service';

interface DeviceCache {
  data?: DeviceModel;
  lastUpdate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  timeTelemetry: Date;
  serviceBase64 = Base64;
  private deviceCache = {} as DeviceCache;
  private cacheExpiryInSeconds: number;

  constructor(
    private backEndCommunicationService: BackEndCommunicationService,
    private deviceStore: DeviceStoreService,
    private router: Router,
    private matDialog: MatDialog,
    private snackMessageService: SnackMessageService
  ) {
    this.cacheExpiryInSeconds = 10;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  public addStatusMessage(message: string, detailStates?: string[]): any {
    if (detailStates) {
      if (detailStates.indexOf(message) === -1) {
        detailStates.push(message);
      }
    }
    return detailStates;
  }

  public setCacheExpirationInSeconds(seconds: number): void {
    this.cacheExpiryInSeconds = seconds;
  }

  addSecondsCache(time) {
    this.cacheExpiryInSeconds = this.cacheExpiryInSeconds + time;
  }

  public getDeviceCache = (deviceId: string): Observable<DeviceModel> => {
    if (this.isCacheValid(deviceId)) {
      return this.deviceStore.getDevice();
    } else {
      this.deviceCache.lastUpdate = new Date();
      if (this.cacheExpiryInSeconds <= 15 && this.cacheExpiryInSeconds > 6) {
        this.addSecondsCache(2);
      }
      if (this.cacheExpiryInSeconds <= 6) {
        this.addSecondsCache(1);
      }
      this.backEndCommunicationService.getResource('/devices/' + deviceId).then(
        (response: any) => {
          this.deviceCache.data = response;
          this.deviceStore.setDevice(response);
          return response;
        },
        (error: any) => {
          this.snackMessageService.readError(error);
        }
      );
    }
  };

  convertLatDMS(lat) {
    const latitude = this.toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? 'N' : 'S';

    return `${latitude} " ${latitudeCardinal}`;
  }

  public removeStatusMessage(message: string, detailStates?): any {
    if (detailStates) {
      const index = detailStates.indexOf(message);
      if (index > -1) {
        detailStates.splice(index, 1);
      }
    }

    return detailStates;
  }

  toDegreesMinutesAndSeconds(coordinate): string {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    const direction = coordinate < 0 ? ' -' : '';

    return `${direction}${degrees}°${minutes}'${seconds}`;
  }

  convertLngDMS(lng) {
    const longitude = this.toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? 'E' : 'W';

    return `${longitude}" ${longitudeCardinal}`;
  }

  cacheRequestTelemetry() {
    const mili = this.timeTelemetry.getTime();
    const actualTime = new Date().getTime();
    const diff = (actualTime - mili) / 1000;

    if (diff > 5) {
      this.setTime();
      return true;
    } else {
      return false;
    }
  }

  setTime(): void {
    this.timeTelemetry = new Date();
  }

  conversorString(texto: any) {
    return this.isBase64(texto) ? Buffer.from(texto, 'base64').toString() : texto;
  }

  isBase64(str: string): boolean {
    if (str.length % 4 !== 0) {
      return false;
    }
    const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
    return base64Regex.test(str);
  }

  conversorBase64(texto: any) {
    return Buffer.from(texto).toString('base64');
  }

  conversorStringOld(texto: any) {
    return Buffer.from(texto, 'base64').toString();
  }

  conversorNumberBase64Old(texto: any) {
    return Buffer.from(texto, 'base64');
  }

  conversorBase64Old(texto: any) {
    return Buffer.from(texto).toString('base64');
  }

  getRoles(roles): string {
    const rol =
      roles.findIndex((elem) => elem === 'role_company_admin') !== -1
        ? 'Administrator'
        : roles.findIndex((elem) => elem === 'role_company_edit_plus') !== -1
          ? 'Supervisor'
          : roles.findIndex((elem) => elem === 'role_company_edit') !== -1
            ? 'Editor'
            : roles.findIndex((elem) => elem === 'role_company_read') !== -1
              ? 'Viewer'
              : '';

    return rol;
  }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {
      return 0;
    }
    return (a < b ? -1 : 1) * (isAsc ? -1 : 1);
  }

  opened(isPanelOpen: boolean, myControl: FormControl): [boolean, FormControl] {
    isPanelOpen = true;
    myControl.setValue('');
    return [isPanelOpen, myControl];
  }

  closePanel(event, autocomplete: MatAutocompleteTrigger) {
    event.stopPropagation();
    autocomplete.closePanel();
  }

  changeOptionSelected(event, list) {
    let selectName = '';
    let selected = '';
    if (event) {
      selectName = event.option.value;
      selected = list.find((option) => option.name === selectName)._id;
    }
    return [selectName, selected];
  }

  openPanel(autocomplete: MatAutocompleteTrigger, groupList, selected, matAutocomplete) {
    autocomplete.openPanel();
    let selectName = groupList.find((group) => group._id === selected)?.name;
    setTimeout(() => {
      matAutocomplete.options.forEach((option) => {
        if (option.value === selectName) {
          option.select();
        }
      });
    }, 200);
    return selectName;
  }

  closed(isPanelOpen: boolean, myControl: FormControl, selectName: string): [boolean, FormControl] {
    isPanelOpen = false;
    if (myControl.value.length === 0) {
      myControl.setValue(selectName);
    }
    return [isPanelOpen, myControl];
  }

  _filter(value: string, options): string[] {
    const filterValue = value.toLowerCase();
    const result = options.filter((option) => option.toLowerCase().includes(filterValue));
    return result.length === 0 ? ['No results found'] : result;
  }

  sortName(list) {
    return list.sort((a, b) => {
      const nombreA = a.name.toLowerCase();
      const nombreB = b.name.toLowerCase();

      if (nombreA < nombreB) return -1;
      if (nombreA > nombreB) return 1;
      return 0;
    });
  }

  public getTimeAgoWithHours = (date: Date | string): string => {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds === 0) {
      return 'loading...';
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInHours >= 24) {
      return date.toLocaleDateString(navigator.language, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    }

    let string = 'ago';

    if (diffInHours > 0) {
      string = `${diffInHours}h `;
      const remainingMinutes = diffInMinutes - diffInHours * 60;
      if (remainingMinutes > 0) {
        string += `${remainingMinutes}min `;
      }
    } else if (diffInMinutes > 0) {
      string = `${diffInMinutes}min `;
    } else {
      string = `${diffInSeconds}sec `;
    }

    return string + 'ago';
  };
  public getTimeNode = (date: Date | string): string => {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    let string = '';
    string = date.toLocaleDateString(navigator.language, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return string;
  };

  openModalPermission(width) {
    const dialogError403 = this.matDialog.open(ModalErrorTierPermissionComponent, {
      width: width > 700 ? '400px' : '100vw',
      height: 'auto',
      maxWidth: width > 700 ? '600px' : '100vw',
      maxHeight: width > 700 ? 'calc(100vh - 40px)' : '100vh',
      panelClass: 'white',
      backdropClass: 'backdropBackground',
      disableClose: true,
      autoFocus: false,
      data: {
        errorStatus: 403,
        errorCode: 'E103'
      }
    });
  }

  sortSpecialAppModel(list) {
    return list.sort((a, b) => {
      let _order: number;
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA === nameB) {
        if (a.iconType < b.iconType) {
          _order = -1;
        }
        if (a.iconType > b.iconType) {
          _order = 1;
        }
        if (a.iconType === b.iconType) {
          _order = 0;
        }
      }
      if (nameA < nameB) {
        _order = -1;
      }
      if (nameA > nameB) {
        _order = 1;
      }

      return _order;
    });
  }

  sortSpecialAppModelVersions(list) {
    return list.sort((a, b) => {
      let _order: number;
      const timeA = new Date(a.created).getTime();
      const timeB = new Date(b.created).getTime();
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (timeA === timeB) {
        if (nameA < nameB) {
          _order = -1;
        }
        if (nameA > nameB) {
          _order = 1;
        }
        if (nameA === nameB) {
          _order = 0;
        }
      }
      if (timeA < timeB) {
        _order = 1;
      }
      if (timeA > timeB) {
        _order = -1;
      }

      return _order;
    });
  }

  getIcon(appModelType: number, engine?: number): string {
    let _temp: string[] = [
      '00_marketplace',
      '01_docker',
      '03_tensorflow',
      '04_onnx',
      '05_pytorch',
      '06_scikit',
      '07_xgboost'
    ];
    let icon: string;
    switch (appModelType) {
      case 0:
        icon = engine === undefined ? _temp[1] : _temp[2];
        break;
      case 1:
        icon = engine === undefined ? _temp[0] : _temp[3];
        break;
      case 2:
        icon = _temp[4];
        break;
      case 3:
        icon = _temp[5];
        break;
      case 4:
        icon = _temp[6];
        break;
      default:
        icon = _temp[1];
        break;
    }

    return icon;
  }

  getClass(appModelType: number, engine?: number): string {
    let _temp: string[] = [
      'marketplace',
      'docker',
      'tensorflow',
      'onnx',
      'pytorch',
      'scikit',
      'xgboost'
    ];
    let classCard: string;
    switch (appModelType) {
      case 0:
        classCard = engine === undefined ? _temp[1] : _temp[2];
        break;
      case 1:
        classCard = engine === undefined ? _temp[0] : _temp[3];
        break;
      case 2:
        classCard = _temp[4];
        break;
      case 3:
        classCard = _temp[5];
        break;
      case 4:
        classCard = _temp[6];
        break;
      default:
        classCard = _temp[1];
        break;
    }

    return classCard;
  }

  private getCacheElapsedTimeInSeconds = (deviceId: string): number => {
    if (this.deviceCache && this.deviceCache.lastUpdate) {
      const actualTime = new Date();
      const cacheTime = this.deviceCache.lastUpdate;

      const elapsedTime = (actualTime.getTime() - cacheTime.getTime()) / 1000;
      return elapsedTime;
    } else {
      return this.cacheExpiryInSeconds * 10;
    }
  };

  private isCacheValid = (deviceId: string): Boolean => {
    return (
      this.deviceCache && this.getCacheElapsedTimeInSeconds(deviceId) < this.cacheExpiryInSeconds
    );
  };
}
